import Link from "next/link";

export default function RoomButton({href, children, className, onClick}) {
    return (
        <Link href={href} className={`${className}`} passHref>
            <div className='flex justify-center cursor-pointer'>
                <a className="relative">
                    <div className="absolute flex flex-row justify-center top-[-24px] left-0 right-0">
                        <div
                            className="rounded-full w-[94px] h-[94px] bg-white bg-opacity-70 border-4 border-white flex justify-center items-center">
                            <div className="rounded-full w-[94px] h-[94px] bg-white animate-ping">
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${className} hover-scale button h-10 sm:h-[44px] pt-2 pb-1 px-8 w-fit drop-shadow-lg bg-white text-sm md:text-md rounded-full text-gray-dark uppercase flex justify-center items-center truncate`}
                        onClick={onClick}>
                        {children}
                    </div>
                </a>
            </div>
        </Link>
    );
}
