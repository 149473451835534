import {DateTime} from "luxon";
import {useRouter} from "next/router";
import {useState} from "react";
import VideoJS from "/components/shared/VideoJS.jsx";

export default function CardItem({event}) {
    let [hovered, setHovered] = useState(false);
    const router = useRouter();
    //hovered = true;

    //console.log('SCROILL OFFSET', scrollOffset);
    let height = 244; //244;
    let style = {height};
    if (hovered) {
        style = {
            transform: "scale(1.7) translateY(36px)",
            //zIndex: 1000,
            //height,
            height: height - 34,
            // position: 'absolute',
            // top: 208 + scrollOffset
        };
    }

    return (
        <div className="mb-4 mr-4 w-44" style={{height}}>
            <div
                className="z-10 grow cursor-pointer relative overflow-hidden bg-white hover:z-50 hover:drop-shadow-2xl hover:rounded-xl"
                onMouseLeave={() => setHovered(false)}
                onMouseOver={() => setTimeout(() => setHovered(true), 0)}
                onClick={() => router.push(`/theater-details/${event.slug}/#gallerie`)}
                style={style}
            >
                <div className="w-44">
                    <img
                        className="absolute top-0 bottom-0 left-0 right-0 detail-img "
                        style={{zIndex: 9}}
                        src={event.poster || event.picture || "/assets/images/curtain.jpg"}
                    />
                    {hovered ? (
                        event.trailer ? (
                            <VideoJS video={event.trailer} />
                        ) : (
                            <img
                                src={
                                    event.poster || event.picture || "/assets/images/curtain.jpg"
                                }
                            />
                        )
                    ) : (
                        ""
                    )}
                </div>
                <div
                    className={`${
                        hovered ? "opacity-100" : "opacity-0"
                    } card-details absolute bottom-0 left-0 right-0 bg-white px-2`}
                >
                    <div className="card-details-title">{event.title}</div>
                    <div className="card-details-duration">
                        {event.custom_data?.duration_description}
                    </div>
                    <div className="flex flex-row">
                        {event.age_restriction && (
                            <span className="card-details-tag">
                ab {event.age_restriction} Jahren
              </span>
                        )}
                    </div>
                    <div className="flex justify-between my-2">
                        <div className="card-details-time">
                            {event.next_subevent ? (
                                <div>
                                    {DateTime.fromISO(event.next_subevent?.start_date)
                                        .setLocale("de")
                                        .toFormat("ccc, dd.LL.yyyy")}
                                </div>
                            ) : null}
                        </div>
                        <div
                            className="z-50"
                            onClick={(e) => {
                                e.stopPropagation();
                                router.push(`/theater-details/${event?.slug}#tickets`);
                            }}
                        >
                            <a className="card-details-tickets">tickets</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
