import Link from "next/link";
import React from "react";

export default function ViewButton({href, icon, children, className}) {
    return (
        <Link href={href} passHref>
            <a>
                <div
                    className={`${className} w-max bg-gold text-sm tracking-wide text-white uppercase flex justify-center items-center rounded-full cursor-pointer`}>
                    {icon ? (
                        <div>
                            {icon}
                        </div>
                    ) : null}
                    <div className="font-bold font-cooper-hewitt-semibold">{children}</div>
                </div>
            </a>
        </Link>
    );
}
