import Link from "next/link";
import {useEffect, useState} from "react";
import TicketCard from "/components/shared/TicketCard";
import {formatCountdown} from "/utils";
import {DateTime, Duration} from "luxon";

export function TicketCardWrapper({show, hideTicketsButton, hover = false}) {
    const [now, setNow] = useState();
    const [count, setCount] = useState(true);

    let start_date = DateTime.fromISO(show.start_date);
    let end_date = DateTime.fromISO(show.end_date);

    let time_of_next_event =
        start_date > DateTime.now()
            ? formatCountdown(start_date)
            : end_date > DateTime.now()
                ? "LIVE"
                : "VORBEI";
    let next_event_label = null;
    if (time_of_next_event) {
        next_event_label = "Nächste Veranstaltung";
    }

    useEffect(() => {
        if (start_date.invalid) {
            return;
        }
        let ms = start_date - DateTime.now();

        // Init the first time
        if (!now) {
            setNow(DateTime.now());
        }

        if (ms <= 0) {
            if (end_date.invalid) {
                return;
            }
            ms = end_date - DateTime.now();
        }
        let duration = Duration.fromMillis(ms).toFull();

        if (ms > 0) {
            const timeout = setTimeout(() => {
                setNow(DateTime.now());
            }, duration.get("seconds") * 1000 + duration.get("milliseconds"));

            return () => {
                clearTimeout(timeout);
            };
        }
        // Gets called on every render, so the timeout has max accuracy (JS timeout drift)
    });

    return (
        <Link
            href={
                now && now >= start_date.minus({hours: 3})
                    ? `/ticket-validation/${show.event?.slug}/${show.id}`
                    : `/theater-details/${show.event?.slug}#gallerie`
            }
            passHref
        >
            <a
                className={`block cursor-pointer mb-4 transition-transform ${
                    hover ? "hover:scale-105" : ""
                }`}
            >
                <TicketCard
                    src={show.event?.poster || show.event?.picture}
                    event={show.event}
                    next_event={next_event_label}
                    time_of_next_event={time_of_next_event}
                    event_title={show.event?.title}
                    hideTicketsButton={hideTicketsButton}
                    start_date={start_date}
                />
            </a>
        </Link>
    );
}
