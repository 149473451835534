import React, { useEffect } from "react";
import ReactPlayer from "react-player";

export default ({
  video,
  className,
  startPlaying = true,
  muted = true,
  controls = false,
  onPlay = () => {},
}) => {
  const playerRef = React.useRef();

  return (
    <ReactPlayer
        height="auto"
        width="100%"
        playing={startPlaying}
        controls={controls}
        muted={muted}
        style={{aspectRatio:'16/9'}}
        ref={playerRef}
        url={video?.src}
        className={className}
        onPlay={onPlay}
        playbackRate={1}
        config={{
        file: {
          attributes: {
            poster: video.poster,
          },
        },
      }}
    />
  );
};
