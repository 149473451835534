import Headline from "./Headline.jsx";
import RoomButton from "./buttons/RoomButton";
import {TicketCardWrapper} from "/helpers/TicketCardWrapper";
import {useContext} from "react";
import AppContext from "/context/AppContext";
import {SvgTheaterCurtains1} from "./SvgIcons";
import {flattenProductionsToShows} from "/utils";

export default function UpcomingShows() {
    const {productions} = useContext(AppContext);

    const shows = flattenProductionsToShows(productions);
    const sortedShows = shows.sort(function (previous, next) {
        return (
            new Date(previous.start_date) -
            new Date(next.start_date)
        );
    });

    if (!shows.length) {
        return <div>Lade Termine...</div>;
    }

    // Need next upcoming shows.

    return (
        <div
            className="px-8 py-4 w-fit 2xl:w-[30vw] h-116 mt-28 flex flex-col items-center bg-black-rgba rounded-2xl">
            <Headline>Die nächsten Vorstellungen</Headline>
            <div>
                {sortedShows.slice(0, 3).map(show => (
                    <div key={show.slug} className="my-2">
                        <TicketCardWrapper show={show} hover={true}/>
                    </div>
                ))}
            </div>
            <div className="mt-4">
                <RoomButton href="/schedule" className="py-2 mb-4">
                    <div className="mr-2">{SvgTheaterCurtains1("#AC8243")}</div>
                    Spielplan
                </RoomButton>
            </div>
        </div>
    );
}
